import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject, fromEvent, combineLatest, Observable } from 'rxjs';
import { auditTime, takeUntil, map, withLatestFrom, filter, tap } from 'rxjs/operators';
import { Profile } from 'src/app/plasma-ui-common/synlab-access-ui-components/core/profile/profile.service';
import { LocalePickerModule } from 'src/app/plasma-ui-common/synlab-access-ui-components/locale-picker/locale-picker.component';
import { Link } from 'src/app/plasma-ui-common/synlab-access-ui-components/model/link.model';
import { MIN_DESKTOP_WIDTH } from 'src/app/plasma-ui-common/synlab-access-ui-components/core/constants';
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { HeaderSelectors } from "src/app/@store/selectors/header.selectors";
import { ResultActionsV2 } from "src/app/@store/actions/results.actionsV2";
import { AuthService } from "src/app/service/auth/auth.service";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { ConfigService } from "src/app/service/config/config.service";
import { HeaderActions } from 'src/app/@store/actions/header.actions';
import { claimTypes, claimValue, userTypes, whiteLabelNames } from 'src/app/appsettings';
import { ResultV2Selectors } from 'src/app/@store/selectors/resultsV2.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Ward } from 'src/app/models/related-users.model';
import { LoggerService } from 'src/app/service/logger.service';
import { SuomiWardSelectorComponent } from '../suomi-ward-selector/suomi-ward-selector.component';
import {MatRadioModule} from '@angular/material/radio';

@Component({
  selector: 'lib-header-switch-profile',
  templateUrl: './header-switch-profile.component.html',
  styleUrls: ['./header-switch-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSwitchProfileComponent implements OnDestroy {
  @Input() links: Link[] = [];
  @Input() brandLogo = '/assets/img/synlab-logo.svg';
  @Input() myAccountLink: string;
  @Input() brandLogoLink: string;
  @Input() disableLinks = false;
  @Input() user?: Profile;

  @Output() onLogout = new EventEmitter();
  @Output() onMyAccount = new EventEmitter();

  isMobile$ = new BehaviorSubject<boolean>(document.documentElement.clientWidth  < MIN_DESKTOP_WIDTH);
  isMobileMenuOpen$ = new BehaviorSubject<boolean>(false);
  destroy$: Subject<boolean> = new Subject<boolean>();

  openWardSelection = false;

  chosenUserName = '';
  chosenUserInitial = '';
  allowProfileSwitch = false;
  selectedWardSynlabId = '';

  wards$ = this.store.pipe(select(HeaderSelectors.getWards));
  currentUserSynlabId$ = this.store.pipe(select(StsSelectors.getSynlabId));
  currentUserFamilyName$ = this.store.pipe(select(StsSelectors.getFirstName));
  currentUserGivenName$ = this.store.pipe(select(StsSelectors.getLastName));
  currentClaims$ = this.store.pipe(select(StsSelectors.getProfile), filter(claims => Array.isArray(claims)));

  enableSuomiWardSelector$ = combineLatest([
    this.currentClaims$,
    this.store.select(StsSelectors.getWhiteLabelName)
  ]).pipe(map(([claims, tenant]) => {
    const isFinland = tenant === whiteLabelNames.FINLAND;
    const usertype = claims?.find(claim => claim.claimType === claimTypes.USER_TYPE)?.claimValue;
    const isSynlabAccessuser = usertype === userTypes.SYNLAB_ACCESS_USER;

    return isFinland && isSynlabAccessuser;
  }))

  wardsAndUser$ = combineLatest([
    this.wards$,
    this.currentUserSynlabId$,
    this.currentUserFamilyName$,
    this.currentUserGivenName$,
    this.currentClaims$
  ]).pipe(map(([wards, currentUserSynlabId, currentUserFamilyName, currentUserGivenName, currentClaims]) => {
    if (!wards || !currentUserSynlabId) {
      return [];
    }
    return [
      {
        synlabId: currentUserSynlabId,
        firstName: currentUserFamilyName,
        lastName: currentUserGivenName,
        isVerified: true,
        ssn: currentClaims.find(c => c.claimType == claimTypes.SSN)?.claimValue,
        nationality: currentClaims.find(c => c.claimType == claimTypes.NATIONALITY)?.claimValue,
        email: currentClaims.find(c => c.claimType == claimTypes.EMAIL)?.claimValue,
        no_email: currentClaims.find(c => c.claimType == claimTypes.NO_EMAIL)?.claimValue.toLowerCase() == claimValue.TRUE,
      },
      ...wards
    ]
  }))

  displayIdenityLabel$ = combineLatest([
    this.store.pipe(select(ResultV2Selectors.getSelectedWardSynlabId)),
    this.store.pipe(select(StsSelectors.getSynlabId)),
    this.currentClaims$.pipe(map(currentClaims => currentClaims.find(c => c.claimType == claimTypes.NATIONALITY)?.claimValue)),
    this.wardsAndUser$.pipe(filter(wards => !!wards))
  ]).pipe(
    map(([wardSynlabId, loggedSynlabId, loggedIdNationality, wards]) => {
      const synlabId = wardSynlabId ?? loggedSynlabId;
      const selectedUser = wards.find(w => w.synlabId == synlabId);
      const nationality = selectedUser?.nationality ?? loggedIdNationality;
      if (nationality == 'ee') {
        return selectedUser?.ssn
      }
      if (!selectedUser?.no_email) {
        return selectedUser?.email
      }
    })
  )

  constructor(
    private _router: Router,
    private store: Store<AppState>,
    public auth: AuthService,
    public cnfg: ConfigService,
    private _transloco:TranslocoService,
    private route: ActivatedRoute,
    private actions$: Actions,
    private logr: LoggerService
  ) {
    _router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.isMobileMenuOpen$.next(false));

    _router.events
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.store.pipe(select(StsSelectors.isSuperSupport)))
      )
      .subscribe(([evt, isSuperSupport]) => {
        if (evt instanceof NavigationEnd) {
          this.allowProfileSwitch = evt.url.startsWith("/results") && isSuperSupport === false;
        }
      });

    // load results for ward account if query string has "account" parameter
    // else load parent account
    combineLatest([
      this.wardsAndUser$.pipe(filter(wards=>!!wards.length)),
      this.route.queryParams.pipe(map(params=>params?.account), filter(selected=>!!selected)),
    ]).pipe(tap(([wards, selected])=>{
      const selectedWard:Ward = <any>wards.find(ward=>ward.synlabId==selected);
      const canProceed = selectedWard?.isVerified && !selectedWard?.isAdult;

      this.logr.log({ selectedWard, canProceed });
      if(canProceed) { this.changeSelectedWard(selectedWard) }
    })).subscribe();

    this.actions$.pipe(ofType(ResultActionsV2.wardChanged), tap(({ward})=>{
      this.selectedWardSynlabId = ward.synlabId;
      this.chosenUserName = ward?.firstName + " " + ward?.lastName;
      this.chosenUserInitial = ward.firstName + " " + ward?.lastName[0] + ".";
      this.openWardSelection = false;
    })).subscribe();
  }

  baseProfileUrl$: Observable<string> =
    combineLatest([
      this.store.pipe(select(StsSelectors.getbaseProfileUrl)),
      this._transloco.langChanges$
    ]).pipe(
      map(([link,lang]) => link + '/?langCode='+lang)
    );

  resize$ = fromEvent(window, 'resize')
    .pipe(takeUntil(this.destroy$), auditTime(200))
      .subscribe(() => {
    this.isMobile$.next(document.documentElement.clientWidth  < MIN_DESKTOP_WIDTH);
  });

  ngOnInit() {
    this.currentUserSynlabId$.subscribe((synlabId) => {
      if(synlabId) {
        this.store.dispatch(HeaderActions.getRelatedUsers({ synlabId: synlabId }));
      }
    });
  }

  toggleMenu() {
    this.isMobileMenuOpen$.next(!this.isMobileMenuOpen$.getValue());
  }

  logout() {
    this.onLogout.emit()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  switchToPreventionWellnes() {
    this.selectedWardSynlabId = "";
    this.chosenUserName = "";
    this.chosenUserInitial = "";
  }

  changeSelectedWard(ward: any) {
    this.logr.log('changeSelectedWard() has been called!');
    this.store.dispatch(ResultActionsV2.wardChanged({ ward: ward }));
  }

  onOpenWardSelection(e: any) {
    if (e.target.closest('#dropdownMenuButton') && !this.openWardSelection) {
      this.openWardSelection = true;
    }
  }

  onCloseWardSelection(e: any) {
    if ((!e.target.closest('.ward-selection-container') || e.target.closest('.close-btn'))
      && this.openWardSelection
    ) {
      this.openWardSelection = false;
    }
  }

  get loggedUser() {
    const { profileInfo } = this.auth;
    if (!profileInfo) return "NO";

    return `${ profileInfo.given_name } ${ profileInfo.family_name }`;
  }

  get loggedUserInitial() {
    const { profileInfo } = this.auth;
    if (!profileInfo) return "NO";

    return `${ profileInfo.given_name } ${ profileInfo.family_name[0] }.`;
  }

}

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule, MatButtonModule, MatMenuModule, MatIconModule, LocalePickerModule, MatRadioModule ],
  declarations: [HeaderSwitchProfileComponent, SuomiWardSelectorComponent],
  exports: [HeaderSwitchProfileComponent]
})
export class HeaderSwitchProfileModule {}
